import { template as template_055214ac65294ce092920b6a6855c67b } from "@ember/template-compiler";
const SidebarSectionMessage = template_055214ac65294ce092920b6a6855c67b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

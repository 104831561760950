import { template as template_62101cf5d69e43d79f2cb1cd4536f6b8 } from "@ember/template-compiler";
const WelcomeHeader = template_62101cf5d69e43d79f2cb1cd4536f6b8(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_03d8197a1e5d48018e799b9cb3d01b18 } from "@ember/template-compiler";
const FKLabel = template_03d8197a1e5d48018e799b9cb3d01b18(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
